import React from 'react';
import {
  Card,
  CardSection,
  ParagraphHeader,
  Paragraph,
  EmptyLayout,
  Gutter,
  useBreakpoint,
} from '@axiom/ui';

import SavedEmptyImage from '../../public/svg/saved-carousel-empty.svg';

export const SavedTalentCarouselEmptyStateCard = () => {
  const { isMobile } = useBreakpoint();
  return (
    <Card name="SAVED_CAROUSEL_EMPTY_STATE_CARD" stretched>
      <CardSection>
        <EmptyLayout
          graphicPosition="right"
          graphic={
            !isMobile ? (
              <div>
                <SavedEmptyImage
                  data-test="empty-image"
                  height="264px"
                  width="324px"
                />
              </div>
            ) : null
          }
        >
          <ParagraphHeader>
            Recently saved talent will appear here
          </ParagraphHeader>
          <Gutter bottom="16px" />
          <Paragraph>
            When you come across legal professionals that fit your needs, save
            their talent profiles to access them later. Click the save button on
            the talent card, and view your saved talent here or in the “Saved”
            tab on the Browse Talent screen.
          </Paragraph>
        </EmptyLayout>
      </CardSection>
    </Card>
  );
};
