import React, { useState } from 'react';
import {
  Button,
  Banner,
  Checkbox,
  Flashy,
  Form,
  FormGroup,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  IconButton,
  ImageCircle,
  Input,
  Layout,
  LayoutItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  SmallHeader,
  CandidateProfileUtil,
  Icon,
} from '@axiom/ui';
import { PossibleImageSizes } from '@axiom/const';
import {
  Candidate,
  User,
  CalendarEventType,
  Contact,
  AfcSubmission,
  Account,
} from '@axiom/validation';
import { z } from 'zod';

const formSchema = z
  .object({
    contacts: z.array(z.string().uuid()),
    isZoomNotPreferred: z.boolean().nullable(),
    adHocContacts: z.array(
      z
        .object({
          firstName: z
            .string()
            .refine(value => (value.trim().length === 0 ? null : value), {
              message: 'Required',
            }),
          lastName: z
            .string()
            .refine(value => (value.trim().length === 0 ? null : value), {
              message: 'Required',
            }),
          email: z.string().email().trim(),
        })
        .optional()
    ),
    errorSelectAtLeastOne: z.string().optional(),
    errorMissMatchEmailDomain: z.string().optional(),
  })
  .refine(
    ({ contacts, adHocContacts }) => {
      // checking for no client selected or added
      const hasSelected: boolean = contacts.length >= 1;
      const hasAdHoc: boolean = adHocContacts.length >= 1;
      const isPopulated: boolean =
        hasAdHoc ||
        !adHocContacts.find(contact => {
          return Object.values(contact).find(c => !c);
        });

      return hasSelected || (hasAdHoc && isPopulated);
    },
    {
      message: 'At least one contact must be selected or added',
      path: ['errorSelectAtLeastOne'],
    }
  );

export type InterviewContactsType = z.infer<typeof formSchema>;

type SetValueType = (
  values: React.SetStateAction<InterviewContactsType>,
  shouldValidate?: boolean
) => void;

export type InterviewConfirmModalType = {
  account: Account;
  loggedInUser: User;
  submission: AfcSubmission;
  candidate: Candidate;
  onClose: (arg0: boolean) => void;
  onConfirm: (
    arg: InterviewContactsType,
    scheduledEvent?: CalendarEventType
  ) => Promise<unknown>;
  scheduledEvent?: CalendarEventType;
  interviewDate?: string;
  primaryContactId?: Contact['id'];
  onBackHandler?: () => void;
};

type ContactOptionType = { value: string; label: string };

export const ClientEngagementsTalentInterviewConfirmModal = ({
  account,
  loggedInUser,
  submission,
  candidate,
  onClose,
  onConfirm,
  scheduledEvent,
  interviewDate,
  primaryContactId,
  onBackHandler,
}: InterviewConfirmModalType) => {
  const [adHocContactsCount, setAdHocContactsCount] = useState(0);
  const [isInterviewTimeUnavailable, setIsInterviewTimeUnavailable] =
    useState(false);
  const initValues: InterviewContactsType = {
    contacts: [],
    adHocContacts: [],
    isZoomNotPreferred: account?.preferredVideoClient !== 'Zoom',
  };

  let currentUserIsAContact = false;
  const contacts: ContactOptionType[] =
    submission?.contacts?.reduce(
      (
        crnt,
        {
          fullName,
          email,
          id,
        }: {
          fullName: string;
          email: string;
          id: string;
        }
      ) => {
        // Don't add the client user to the selection list if no linked user record exists
        if (!id) {
          return crnt;
        }

        let action: 'push' | 'unshift' = 'push';
        if (loggedInUser?.contactId === id) {
          action = 'unshift';
          currentUserIsAContact = true;
          initValues.contacts.push(id);
        } else if (
          primaryContactId &&
          id === primaryContactId &&
          !loggedInUser?.contactId
        ) {
          action = 'unshift';
          initValues.contacts.push(id);
        }

        crnt[action]({
          label: `${fullName} (${email})`,
          value: id,
        });

        return crnt;
      },
      [] as ContactOptionType[]
    ) || [];

  if (loggedInUser?.contactId && !currentUserIsAContact) {
    contacts.unshift({
      label: `${loggedInUser.fullName} (${loggedInUser.email})`,
      value: loggedInUser.contactId,
    });
    initValues.contacts.push(loggedInUser.contactId);
  }

  const handleOnSubmit = async (
    formData: InterviewContactsType,
    {
      setFieldError,
    }: { setFieldError: (field: string, message: string) => void }
  ) => {
    try {
      await onConfirm({ ...initValues, ...formData }, scheduledEvent);
    } catch (error) {
      const errorObj = error?.getResponse();

      if (errorObj?.statusCode === 400) {
        if (errorObj?.text.includes('domain')) {
          setFieldError(
            `errorMissMatchEmailDomain`,
            'For enhanced security, all attendees must have matching email domains from the same organization.'
          );

          return;
        }
        setIsInterviewTimeUnavailable(true);
      } else {
        throw error;
      }
    }
  };

  const handleOnClickOneMore = (setValues: SetValueType) => {
    setValues(
      (prev: InterviewContactsType) => ({
        ...prev,
        adHocContacts: [
          ...prev.adHocContacts,
          {
            firstName: null,
            lastName: null,
            email: null,
          },
        ],
      }),
      false
    );

    setAdHocContactsCount(prev => prev + 1);
  };

  const handleOnClickTrash = (index: number, setValues: SetValueType) => {
    setValues((prev: InterviewContactsType) => {
      const { adHocContacts } = prev;
      adHocContacts.splice(index, 1);
      return { ...prev, adHocContacts };
    }, false);

    setAdHocContactsCount(prev => prev - 1);
  };

  return (
    <Form
      name="MODAL_FORM"
      initialValues={initValues}
      onSubmit={handleOnSubmit}
      schema={formSchema}
    >
      {({ fireSubmit, setValues, values }) => {
        return (
          <Modal name="INTERVIEW_PROMPT">
            <ModalHeader name="HEADER" onClose={() => onClose(false)}>
              <Layout position="middle" wrap>
                <LayoutItem rightGutter="16px">
                  <ImageCircle
                    imageName={candidate.calculatedDisplayName}
                    src={CandidateProfileUtil.getProfileImageUri(
                      candidate,
                      PossibleImageSizes.W_100
                    )}
                    size="small"
                    name="TALENT_IMAGE"
                  />
                </LayoutItem>
                <LayoutItem fluid>
                  <SmallHeader name="HEADER_TEXT">
                    {!scheduledEvent ? 'Request to' : ''} Interview{' '}
                    {candidate.calculatedFirstName}
                  </SmallHeader>
                </LayoutItem>
              </Layout>
            </ModalHeader>
            <ModalSection>
              <Grid>
                <GridRow gutterBottom="16px">
                  <GridColumn>
                    {scheduledEvent && !isInterviewTimeUnavailable && (
                      <Banner name="INTERVIEW_BANNER">
                        <Flashy bold color="controlSecondary">
                          Interview Date{' '}
                        </Flashy>
                        {interviewDate}
                      </Banner>
                    )}
                    {scheduledEvent && isInterviewTimeUnavailable && (
                      <Banner
                        name="INTERVIEW_UNAVAILABLE_BANNER"
                        type="error"
                        impact="high"
                      >
                        <Layout position="middle" horizontalGutter="8px">
                          <Paragraph>
                            <Flashy color="textAlert">
                              <Icon name="alert-filled" />
                            </Flashy>
                          </Paragraph>
                          <Paragraph>
                            The selected interview time is no longer available.
                            Please try again.
                          </Paragraph>
                        </Layout>
                      </Banner>
                    )}
                    {!scheduledEvent && (
                      <Paragraph name="BODY_COPY">
                        We're on it. We will reach out within two business days
                        to arrange your interview with{' '}
                        {candidate.calculatedFirstName}. All attendees will
                        receive an email once the interview is confirmed.
                      </Paragraph>
                    )}
                  </GridColumn>
                </GridRow>
                <GridRow gutterBottom="16px">
                  <GridColumn>
                    <FormGroup
                      label="Who else should we invite to the interview?"
                      description={`Select or add additional attendees below. All contacts selected will receive an invitation to join ${candidate.calculatedFirstName}'s interview.`}
                    >
                      {contacts.map((contact: ContactOptionType) => (
                        <Gutter key={contact.value} bottom="8px">
                          <Checkbox
                            name="contacts"
                            displayValue={contact.label}
                            option={contact.value}
                          />
                        </Gutter>
                      ))}
                    </FormGroup>
                  </GridColumn>
                </GridRow>

                {new Array(adHocContactsCount).fill(null).map((_, i) => (
                  <React.Fragment key={`adHocContacts_${i.toString(10)}`}>
                    <GridRow
                      name="ADHOC_ROW"
                      stretched
                      columns={4}
                      gutterBottom="12px"
                    >
                      <GridColumn widescreenWidth={3} smallScreenWidth={12}>
                        <Input
                          name={`adHocContacts.${i}.firstName`}
                          label="First Name"
                          placeholder="Entered Text"
                        />
                      </GridColumn>
                      <GridColumn widescreenWidth={3} smallScreenWidth={12}>
                        <Input
                          name={`adHocContacts.${i}.lastName`}
                          label="Last Name"
                          placeholder="Entered Text"
                        />
                      </GridColumn>
                      <GridColumn widescreenWidth={5} smallScreenWidth={10}>
                        <Input
                          name={`adHocContacts.${i}.email`}
                          label="Email"
                          placeholder="Entered Text"
                        />
                      </GridColumn>
                      <GridColumn widescreenWidth={1} smallScreenWidth={2}>
                        <Gutter top="32px">
                          <IconButton
                            name="REMOVE_CONTACT"
                            icon="trash"
                            pattern="secondary"
                            variation="minimal"
                            onClick={() => handleOnClickTrash(i, setValues)}
                          />
                        </Gutter>
                      </GridColumn>
                    </GridRow>
                    <GridRow gutterBottom="0">
                      <GridColumn width={12}>
                        {/* for displaying custom error */}
                        <FormGroup name="errorMissMatchEmailDomain" />
                      </GridColumn>
                    </GridRow>
                  </React.Fragment>
                ))}

                <GridRow gutterBottom="12px">
                  <GridColumn width={12}>
                    {/* for displaying custom error */}
                    <FormGroup name="errorSelectAtLeastOne" />
                  </GridColumn>
                </GridRow>

                <GridRow>
                  <GridColumn>
                    <Button
                      pattern="secondary"
                      variation="minimal"
                      icon="plus"
                      iconPosition="right"
                      name="ADD_NEW_BUTTON"
                      onClick={() => handleOnClickOneMore(setValues)}
                    >
                      Add New
                    </Button>
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalSection divider>
              <Checkbox
                option={values.isZoomNotPreferred}
                name="isZoomNotPreferred"
                displayValue="Zoom is not allowed at my organization, please contact me to discuss alternative options."
              />
            </ModalSection>
            <ModalFooter stackableOn="mobile">
              {!isInterviewTimeUnavailable ? (
                <>
                  <Button
                    name="CANCEL"
                    variation="outline"
                    onClick={() => onClose(false)}
                  >
                    Cancel
                  </Button>
                  <Button name="CONFIRM" onClick={fireSubmit}>
                    {scheduledEvent ? 'Schedule' : 'Request'} Interview
                  </Button>
                </>
              ) : (
                <Button name="BACK" variation="outline" onClick={onBackHandler}>
                  Back
                </Button>
              )}
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
