import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { UserConst } from '@axiom/const';
import { Loader, Page, Suspense } from '@axiom/ui';

import AppContainer from './components/AppContainer/AppContainer';
import { AuthRoute } from './routes/AuthRoute';
import { UnauthorizedPage } from './pages/UnauthorizedPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { DefaultPage } from './pages/DefaultPage';
import { BenchPage } from './pages/BenchPage';
import { TalentSearch } from './components/TalentSearch/TalentSearch';
import { ClientEngagementsDashboard } from './components/ClientEngagements/ClientEngagementsDashboard';
import { ClientEngagementsHeaderBanner } from './components/ClientEngagements/ClientEngagementsHeaderBanner';
import { ClientEngagementsEngagement } from './components/ClientEngagements/ClientEngagementsEngagement';
import { ClientEngagementsEngagementTalent } from './components/ClientEngagements/ClientEngagementsEngagementTalent';
import { ClientEngagementsEngagementTalentDetails } from './components/ClientEngagements/ClientEngagementsEngagementTalentDetails';
import { ClientEngagementsEngagementInterviewTalent } from './components/ClientEngagements/ClientEngagementsEngagementInterviewTalent';
import { ClientEngagementsEngagementDetails } from './components/ClientEngagements/ClientEngagementsEngagementDetails';
import { SubmissionPdfDownload } from './components/SubmissionPdfDownload/SubmissionPdfDownload';
import { SubmissionPdfData } from './components/SubmissionPdfData/SubmissionPdfData';
import { CandidateBios } from './components/CandidateBios/CandidateBios';
import { AccessLegalTalentPage } from './pages/AccessLegalTalentPage';
import { EngagementsPage } from './pages/EngagementsPage';
import { MyAxiomPage } from './pages/MyAxiomPage';
import { BenchView } from './components/BenchView/BenchView';
import { BenchSubmissions } from './components/BenchSubmissions/BenchSubmissions';
import { TalentDetailPage } from './pages/TalentDetailPage';
import { MyAxiomDashboard } from './components/MyAxiomDashboard/MyAxiomDashboard';
import { Redirecter } from './components/Redirecter/Redirecter';
import { SplitSubmissionPdfDownload } from './components/SplitSubmissionPdfDownload/SplitSubmissionPdfDownload';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="engagements" />} />
      <Route
        path="bench-submissions/*?"
        element={<Navigate to="/bench/submissions" />}
      />
      <Route path="bench" element={<BenchPage />}>
        <Route path="" element={<BenchView />} />
        <Route path="submissions" element={<BenchSubmissions />} />
      </Route>
      <Route path="access-legal-talent">
        <Route path="page" element={<AccessLegalTalentPage />}>
          <Route path=":pageNumber" element={<TalentSearch />} />
          <Route path="" element={<Navigate to="1" replace />} />
        </Route>
        <Route path="" element={<Navigate to="page" replace />} />
        <Route path=":id" element={<TalentDetailPage />} />
      </Route>

      {/* being deprecated for My Axiom ===================================== START */}
      {/* TODO remove as part of (FR-4550) */}
      <Route path="projects/*" element={<Redirecter to="../engagements" />} />
      <Route path="engagements" element={<EngagementsPage />}>
        <Route path="account" element={<Outlet />}>
          <Route path=":accountId">
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<ClientEngagementsDashboard />} />
            <Route path="submission">
              <Route path="" element={<Navigate to="../dashboard" replace />} />
              <Route
                path=":submissionId"
                element={
                  <>
                    <ClientEngagementsHeaderBanner />
                    <ClientEngagementsEngagement />
                  </>
                }
              >
                <Route path="" element={<Navigate to="matches" replace />} />
                <Route
                  path="matches"
                  element={<ClientEngagementsEngagementTalent />}
                >
                  <Route
                    path=":talentSlug?"
                    element={
                      <Suspense fallback={<Loader type="bars" />}>
                        <ClientEngagementsEngagementTalentDetails />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="interviews"
                  element={<ClientEngagementsEngagementInterviewTalent />}
                />
                <Route
                  path="engagement-details"
                  element={<ClientEngagementsEngagementDetails />}
                />
                <Route
                  path="project-details"
                  element={<Navigate to="../engagement-details" replace />}
                />
                <Route path="*" element={<Navigate to="matches" replace />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path=":accountId">
          <Route
            path=":submissionId"
            element={
              <Suspense fallback={<Loader type="bars" />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              path="pdf"
              element={
                <Page>
                  <SubmissionPdfData />
                </Page>
              }
            />
            <Route
              path="pdf/download"
              element={
                <Suspense>
                  <SubmissionPdfDownload />
                </Suspense>
              }
            />
            <Route path=":candidateId">
              <Route path="download?/bio/anonymous?">
                <Route path="" element={<CandidateBios />} />
                <Route path="*" element={<Navigate to="/404" />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      {/* being deprecated for My Axiom ===================================== END */}

      {/* super secret path for My Axiom ====================================== START */}
      {/* TODO: before going live add redirect from /projects and or /engagements (FR-4550) */}
      <Route path="my-axiom" element={<MyAxiomPage />}>
        <Route path="account" element={<Outlet />}>
          <Route path=":accountId" element={<Outlet />}>
            <Route path="" element={<MyAxiomDashboard />} />

            {/* TODO: before going live add redirect from /dashboard (FR-4550) */}
            <Route
              path="engagements"
              element={<ClientEngagementsDashboard />}
            />
            <Route path="submission">
              <Route
                path=""
                element={<Navigate to="../engagements" replace />}
              />
              <Route
                path=":submissionId"
                element={
                  <>
                    <ClientEngagementsHeaderBanner />
                    <ClientEngagementsEngagement />
                  </>
                }
              >
                <Route path="" element={<Navigate to="matches" replace />} />
                <Route
                  path="matches"
                  element={<ClientEngagementsEngagementTalent />}
                >
                  <Route
                    path=":talentSlug?"
                    element={
                      <Suspense fallback={<Loader type="bars" />}>
                        <ClientEngagementsEngagementTalentDetails />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="interviews"
                  element={<ClientEngagementsEngagementInterviewTalent />}
                />
                <Route
                  path="engagement-details"
                  element={<ClientEngagementsEngagementDetails />}
                />
                <Route path="*" element={<Navigate to="matches" replace />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path=":accountId">
          <Route
            path=":submissionId"
            element={
              <Suspense fallback={<Loader type="bars" />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route
              path="pdf"
              element={
                <Page>
                  <SubmissionPdfData />
                </Page>
              }
            />
            <Route
              path="pdf/split"
              element={
                <Suspense>
                  <Outlet />
                </Suspense>
              }
            >
              <Route path="" element={<Navigate to="./download" replace />} />
              <Route path="download" element={<SplitSubmissionPdfDownload />} />
            </Route>
            <Route
              path="pdf/download"
              element={
                <Suspense>
                  <SubmissionPdfDownload />
                </Suspense>
              }
            />
            <Route path=":candidateId">
              <Route path="download?/bio/anonymous?">
                <Route path="" element={<CandidateBios />} />
                <Route path="*" element={<Navigate to="/404" />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      {/* super secret path for My Axiom ====================================== END */}

      <Route
        path="401"
        element={
          <Page title="AxiomLaw - Unauthorized">
            <AppContainer>
              <UnauthorizedPage />
            </AppContainer>
          </Page>
        }
      />
      <Route
        path="404"
        element={
          <Page title="AxiomLaw - Not Found">
            <AppContainer>
              <NotFoundPage />
            </AppContainer>
          </Page>
        }
      />
      <Route
        path=""
        element={
          <AuthRoute
            roles={[
              UserConst.Roles.ClientUser,
              UserConst.Roles.EnvoyAdmin,
              UserConst.Roles.EnvoyUser,
            ]}
          >
            <Page title="AxiomLaw">
              <AppContainer>
                <DefaultPage />
              </AppContainer>
            </Page>
          </AuthRoute>
        }
      />
      <Route path="*" element={<Navigate to="404" replace />} />
    </Routes>
  );
};
