import React, { useState } from 'react';
import { Candidate, CandidateIndustry } from '@axiom/validation';
import {
  Card,
  CardSection,
  CardFooter,
  Button,
  Well,
  ImageCircle,
  Layout,
  CandidateProfileUtil,
  ParagraphHeader,
  Icon,
  CondensedLarge,
  Gutter,
  CondensedHeader,
  useApi,
  PracticeAreaUtil,
} from '@axiom/ui';
import { PossibleImageSizes } from '@axiom/const';

import { PracticeAreaApi } from '../../api/practiceAreas';
import { TaxonomyApi } from '../../api/taxonomy/taxonomy';
import { LinkUtil } from '../../utils/link-util';
import { CandidateLegacyApi } from '../../api/candidate/candidate-legacy';

export const DiscoverTalentCarouselCard = ({
  candidate,
  industryValue,
}: {
  candidate: Candidate;
  industryValue: CandidateIndustry['industryValue'];
}) => {
  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );
  const { industries } = taxonomy;
  const [favorited, setFavorited] = useState(false);

  const industry = candidate?.industries?.find(
    ind => ind.industryValue === industryValue
  );

  const renderIndustry = () => {
    const industryYOE = industry?.yearsOfExperience;
    const industryName = industries?.[industry?.industryValue]?.displayValue;
    if (industryYOE && Math.round(+industryYOE) === 0) {
      return `Less than 1 Year in ${industryName}`;
    }

    return `${Math.round(+industryYOE)} Years in ${industryName}`;
  };

  const renderCandidateExperience = () => {
    const candidateYOE = candidate?.yearsOfExperience;

    if (candidateYOE && Math.round(candidateYOE) === 0) {
      return 'Less than 1 Year of experience';
    } else if (candidateYOE && Math.floor(candidateYOE) >= 20) {
      return '20+ Years of experience';
    }
    return `${Math.round(candidate?.yearsOfExperience)} Years of experience`;
  };
  return (
    <Card name="DISCOVER_TALENT_CAROUSEL_CARD" stretched>
      <CardSection divider>
        <Well background="blue">
          <Layout horizontalGutter="16px" position="middle">
            <div>
              <ImageCircle
                name="DISCOVER_TALENT_IMAGE"
                size="small"
                background="light blue"
                imageName={candidate?.calculatedDisplayName}
                src={CandidateProfileUtil.getProfileImageUri(
                  candidate,
                  PossibleImageSizes.W_100
                )}
              />
            </div>
            <ParagraphHeader name="DISCOVER_TALENT_NAME">
              {candidate?.calculatedDisplayName}
            </ParagraphHeader>
          </Layout>
          <Gutter bottom="16px" />
          <Layout verticalGutter="16px" direction="vertical">
            <Layout horizontalGutter="16px">
              <Icon name="checkmark" />
              <CondensedLarge name="DISCOVER_CANDIDATE_EXPERIENCE">
                {renderCandidateExperience()}
              </CondensedLarge>
            </Layout>
            <Layout horizontalGutter="16px">
              <Icon name="checkmark" />
              <CondensedLarge name="DISCOVER_INDUSTRY_EXPERIENCE">
                {renderIndustry()}
              </CondensedLarge>
            </Layout>
          </Layout>
        </Well>
        <Gutter bottom="16px" />
        <CondensedHeader>Primary Practice Area</CondensedHeader>
        <CondensedLarge name="DISCOVER_PRACTICE_AREA">
          {PracticeAreaUtil.formatPracticeAreaSpecialty(
            practiceAreas,
            candidate.practiceArea?.id
          )}
        </CondensedLarge>
      </CardSection>
      <CardFooter>
        <Button
          onClick={async () => {
            if (!favorited) {
              await CandidateLegacyApi.createSavedCandidate(candidate.id);
            } else {
              await CandidateLegacyApi.deleteSavedCandidate(candidate.id);
            }
            setFavorited(!favorited);
          }}
          icon={favorited ? 'star-filled' : 'star'}
          variation="outline"
          name="SAVE_TALENT_BUTTON"
        >
          {favorited ? 'Saved' : 'Save'}
        </Button>
        <Button
          toTab={LinkUtil.getSharePath(candidate, practiceAreas)}
          name="VIEW_TALENT_BUTTON"
        >
          View Talent
        </Button>
      </CardFooter>
    </Card>
  );
};
